.cropper-point {
    height: 35px !important;
    width: 35px !important;
    opacity: 1;
    background: rgba(0,0,0,0)

}
.cropper-point.point-nw{
  border-radius: 15px 0px 0px 0px;
  border-left: 5px solid #fff;
  border-top: 5px solid #fff;
}
.cropper-point.point-sw{
  border-radius: 0px 0px 0px 15px;
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
}
.cropper-point.point-ne{
  border-radius: 0px 15px 0px 0px;
  border-right: 5px solid #fff;
  border-top: 5px solid #fff;
}
.cropper-point.point-se{
  border-radius: 0px 0px 15px 0px;
  border-right: 5px solid #fff;
  border-bottom: 5px solid #fff;
}
.cropper-line {
    background-color: #ccc;
}
.cropper-view-box {
    display: block;
    height: 100%;
    outline: none;
    overflow: hidden;
    width: 100%;
}
.cropper-menu{
  position:relative;
    padding:3px;
}
.vertical-spacer{
  margin: 0px 10px;
  border-right: 1px solid #aaa;
  height: 22px;
  position: relative;
  width: 1px;
  display: inline-block;
  top: 5px;
}
@media screen and (max-width: 400px) {
  .vertical-spacer{
    margin: 0px 2px !important;
    padding: 4px;
  }
  .cropper-menu span, .cropper-menu a{
    padding: 4px;
  }
}